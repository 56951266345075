@import '../../../variables.scss';

.AddTagForm {
    min-width: 280px;
}

.FormContent {
    width: 100%;
    min-height: 120px;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;

    .notification,
    .error {
        text-align: left;
        word-break: break-all;
    }
}

.AutoCompleteContainer {
    height: 40px;
}
