@import '../../variables';

.Main {
    display: block;
}

.Support {
    margin: 50px auto 0 auto;
    width: 1200px;
    min-height: 400px;
}

.TextCenter {
    text-align: center;
}

.description {
    padding: 7px;
}

.Table {
    td {
        padding: 7px;
    }

    input,
    select {
        padding: 5px;
        background-color: white;
        border-radius: 3px;
    }

    button {
        padding: 5px 10px;
        background-color: #97c130;
        border-radius: 3px;
        color: white;
        font-size: 14px;
    }
}

.ContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-top: 66px;
    width: 90%;
    height: calc(100% - 66px);
}

@media screen and (min-width: 2000px) {
    .ContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
        margin-top: 66px;
        width: 70%;
        height: calc(100% - 66px);
    }
}

.Scroll {
    overflow-y: auto;
}
