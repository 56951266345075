@import '../../variables.scss';

.notification {
    color: $color-green;
}

.error {
    font-size: $font-size-tiny;
    color: $color-text-orange;
}

.warning {
    font-size: $font-size-tiny;
    color: $color-orange;
    text-align: left;
}
