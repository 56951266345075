@import '../../variables.scss';

.SubmitArea {
    padding: 16px 0;
    min-width: 215px;
    background-color: $color-gray;
}

.QueryBuilder {
    display: flex;
    flex-direction: column;
    min-height: 70px;
    justify-content: space-between;
    background-color: $color-black;
    flex: 1;
}
