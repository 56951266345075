@import '../../../variables.scss';

div.UserInput {
    margin: 0;
    padding: 0;
    width: 100%;

    input {
        color: $color-text;
    }

    form {
        width: 100%;
    }
}
