@import '../../variables.scss';
@import '../../mixins.scss';

.Tabs {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
}

.TabList {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 1px 0;
    padding-left: 0;
}

.TabListItem {
    display: inline-block;
    box-sizing: border-box;
    margin-bottom: -1px;
    padding: 1em;
    width: 100%;
    color: $color-text;
    list-style: none;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;

    @include tabEffects($color-magenta);
}

.TabContent {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.Shadow {
    &::after {
        position: absolute;
        bottom: -32px;
        left: 0;
        z-index: $zIndex-base;
        width: 100%;
        height: 32px;
        box-shadow: 0 -16px 32px 0 $color-light-gray;
        content: '';
        pointer-events: none;
    }
}

.TabList--0 {
    overflow-y: auto;

    @include disabled($color-black, $color-magenta);

    .TabListItem {
        box-shadow: none;
        border-left: solid 4px $color-text-light;
        background-color: $color-text-pale;

        &:hover {
            box-shadow: inset 0 0 $page-header-height $page-header-height
                $color-light-gray-shadow;
        }

        &.Active {
            box-shadow: none;
            background-color: $color-light-gray;
            border-left: solid 4px $color-magenta;
            font-weight: bold;
        }
    }
}

.Tabs--vertical {
    flex-direction: row;
    height: calc(100% - #{$page-header-height});

    .TabList {
        flex: 0 0 auto;
        flex-direction: column;
    }

    .TabContent {
        flex: 1 1 auto;
    }

    & > .TabList {
        width: 120px;

        & > .TabListItem:first-child {
            margin-top: 54px;
        }
    }

    & > .TabContent {
        padding: 0 $padding;
    }
}

.Tabs--horizontal {
    flex-direction: column;

    .TabList {
        flex-direction: row;
        justify-content: space-evenly;
    }

    .TabContent {
        height: calc(100% - #{$page-header-height});
    }
}
