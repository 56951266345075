@import '../../variables.scss';

$indicator-size: 30px;
$indicator-border-radius: 5px;

.DrawerContainer {
    display: block;
    background-color: Transparent;
    position: relative;
    min-width: $indicator-size;
    height: 100%;
}

.DrawerContent {
    height: 100%;
}

.Collapsed,
.Expanded {
    display: flex;
    flex-direction: column-reverse;
    z-index: $zIndex-important;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 20px;

    .DrawerIndicator {
        z-index: $zIndex-tooltip;
        position: absolute;
        bottom: 0;
        line-height: 20px;
        cursor: pointer;

        &::after {
            display: block;
            height: $indicator-size;
            width: $indicator-size;
            border: solid 1px $color-light-gray;
            font-size: 40px;
        }
    }
}

.Collapsed {
    .DrawerIndicator {
        left: 0;

        &::after {
            background-color: $color-light-gray-shadow;
            border-radius: 0 $indicator-border-radius $indicator-border-radius 0;
            content: '\000BB';
        }
    }
}

.Expanded {
    width: 0;

    .DrawerIndicator {
        right: 0;

        &::after {
            content: '\000AB';
            background-color: $color-white-opaque;
            border-radius: $indicator-border-radius 0 0 $indicator-border-radius;
        }
    }
}

.Collapsed .DrawerIndicator:hover {
    &::after {
        background-color: darken($color-white, 16%);
    }
}

.Expanded .DrawerIndicator:hover {
    &::after {
        background-color: lighten($color-white, 16%);
    }
}
