@import '../../variables.scss';

$headerHeight: 45px;
$padding: 16px;
$dotSize: 24px;

.ModalOverlay {
    position: absolute;
    z-index: $zIndex-modal;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $color-gray;
    opacity: 0.5;
    align-content: center;
    justify-content: center;
}

.ModalWindow {
    position: absolute;
    z-index: $zIndex-modal-top;
    min-width: 300px;
    min-height: 200px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    padding: $padding;
    display: flex;
    flex-direction: column;
}

.ModalWindowTitle {
    font-size: $dotSize;
    line-height: $dotSize;
    flex: $headerHeight 0 0;
}

.ModalWindowContent {
    position: relative;
    box-sizing: border-box;
    flex: auto;
    border: solid 1px $color-shadow;
    padding: $padding;
}

.ModalWindowActions {
    flex: 50px 0 0;
}

.Close {
    width: $headerHeight;
    height: $headerHeight;
    line-height: $headerHeight;
    font-size: $headerHeight;
    color: $color-text-dark;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.TecanDot {
    position: absolute;
    width: $dotSize;
    height: $dotSize;
    border-radius: $dotSize/2;
    background-color: $color-text-orange;

    &::before {
        display: inline-block;
        content: '';
        position: absolute;
        width: $dotSize/3;
        height: $dotSize/3;
        left: $dotSize/3;
        bottom: $dotSize/10;
        border-radius: $dotSize/4;
        background-color: $color-white;
    }
}
