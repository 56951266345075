@import '../../../variables.scss';
@import '../../../mixins.scss';

.ResultContainerWrapper {
    position: absolute;
    top: $padding;
    left: $padding;
    right: $padding;
    bottom: $padding;
    display: flex;
    flex-direction: column;
}

.ResultContainer {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    min-height: 0;
    height: 100%;
}

.ResultTitle {
    font-weight: bold;
    text-align: left;
    width: 100%;

    @include ellipsisContent;
}

.ResultContainerActions {
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: $color-light-gray;
    font-size: 0.9em;

    & > :first-child {
        margin-right: 30px;
    }

    .ActionItem {
        padding: 5px 10px;
        margin: 0 10px;
        border: solid 1px $color-text-pale;
        border-radius: 3px;

        & > :first-child {
            margin-right: 5px;
        }
    }

    .Active {
        background-color: lighten($color-green, 15%);
        cursor: pointer;

        & > :first-child {
            font-weight: bold;
        }

        &:hover {
            background-color: lighten($color-green, 30%);
        }
    }

    .Disabled {
        background-color: $color-text-pale;
        color: $color-white;
        pointer-events: unset;
        cursor: not-allowed;
    }
}

.ResultHeader {
    position: relative;
    min-height: $list-header-height;
    background-color: $color-text-pale;
    display: flex;
    flex-direction: row;
}

.ResultHeaderColumn {
    display: flex;
    position: relative;
    align-items: center;
    flex: 1;
    white-space: nowrap;
    text-align: left;
    height: 100%;
    font-size: 0.8em;
    font-weight: bold;
    user-select: none;

    .ColumnName {
        cursor: pointer;
    }
}

.ResultHeaderActions {
    height: 100%;
}

.ResultHeaderCheckbox {
    width: 45px !important;
    height: 100%;
}

.ResultRow {
    display: flex;

    .ListElement {
        margin-left: 10px !important;
        padding: 0 10px 0 5px;
    }
}

.FilterContainer {
    position: absolute;
    top: 30px;
    display: flex;
    width: 100%;
    height: 48px;
    padding: 10px;
    border: 1px solid #ccc;
    background: white;
    z-index: 1;
    cursor: default;

    input[type='text'] {
        flex: 1 1;
        margin-right: 12px;
        min-width: 80px;
    }

    .ClearFilter {
        width: 24px;
        transform: rotate(45deg);
        cursor: pointer;
    }
}

.SortArrowContainer {
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor: pointer;

    .SingleArrowIcon,
    .DoubleArrowIcon {
        width: 100%;
    }

    .SingleArrowIcon.ascending {
        transform: rotate(180deg);
    }
}

.FilterIcon {
    width: 18px;
}

.MaintenanceStatus {
    border: solid 2px $color-white;
}

.MAINTAINED,
.NOT_MAINTAINED,
.MAINTENANCE_EXPIRED,
.PARTIAL_MAINTAINED,
.UNKNOWN {
    display: inline-block;
    width: $font-size-default;
    height: $font-size-default;
    vertical-align: middle;
    border-radius: $font-size-default/2;
}

.MAINTAINED {
    background-color: $color-green;
}

.NOT_MAINTAINED {
    background-color: $color-red;
}

.UNKNOWN {
    background-color: $color-text-pale;
}

.MAINTENANCE_EXPIRED {
    background-color: $color-red;
}

.PARTIAL_MAINTAINED {
    background-color: orange;
}
