@import '../../variables.scss';

.CalendarInputsWrapper {
    display: flex;
    background: transparent;

    & > input:nth-child(2) {
        margin-left: 4px;
    }

    & > input[type='text'].CalendarInput {
        width: 0;
        flex: 1 1 auto;
    }
}

.CalendarInput {
    box-sizing: border-box;
    width: 100%;
}

.CalendarInput:focus {
    outline: none;
}

.CalendarInput:disabled {
    background-color: $color-disabled;
}

.CalendarInput--invalid {
    color: $color-text-orange !important;
}
