/* Font related */

$font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
$font-size-big: 18px;
$font-size-default: 16px;
$font-size-small: 14px;
$font-size-tiny: 12px;
$font-weight-default: 400;
$font-weight-bold: 700;

/* Color related */
$color-text: rgb(102, 102, 102);
$color-text-dark: rgb(17, 17, 17);
$color-text-light: rgb(153, 153, 153);
$color-text-gray: rgb(58, 68, 79);
$color-text-pale: rgb(204, 204, 204);
$color-text-white: rgb(243, 239, 224);
$color-text-blue: rgb(0, 141, 241);
$color-text-orange: rgb(232, 78, 14);

$color-magenta: rgb(232, 79, 152);
$color-red: rgb(150, 0, 0);
$color-blue: rgb(47, 188, 237);
$color-orange: rgb(241, 144, 33);
$color-yellow: rgb(253, 203, 43);
$color-green: rgb(151, 193, 48);
$color-black: rgb(0, 0, 0);
$color-gray: rgb(61, 61, 61);
$color-light-gray: rgb(245, 245, 245);
$color-light-gray-shadow: rgba(245, 245, 245, 0.3);
$color-light-gray-2: rgb(221, 221, 221);
$color-white: rgb(255, 255, 255);

$color-text-orange-dark: darken($color-text-orange, 16%);
$color-magenta-dark: darken($color-magenta, 16%);
$color-blue-dark: darken($color-blue, 16%);
$color-orange-dark: darken($color-orange, 16%);
$color-yellow-dark: darken($color-yellow, 16%);
$color-green-dark: darken($color-green, 20%);
$color-gray-dark: rgb(54, 54, 54);
$color-shadow: rgb(160, 160, 160);

$color-black-light: lighten($color-black, 16%);

$color-white-opaque: rgba($color-white, 0.8);
$color-disabled: $color-light-gray-2;
$color-text-disabled: $color-text;

/* Others */
$minimum-app-width: 1024px;
$page-header-height: 66px;
$query-container-top: 100px;
$list-header-height: 30px;
$space-minimal: 5px;
$space-small: 10px;
$space-normal: 16px;
$padding: 16px;
$margin: 16px;
$margin-small: 8px;
$checkboxSize: 20px;
$line-height: 24px;

$zIndex-base: 1;
$zIndex-important: 2;
$zIndex-tooltip: 3;
$zIndex-header: 4;
$zIndex-modal: 1000;
$zIndex-modal-top: 1001;
$zIndex-snackbar: 1002;

$border-radius: 5px;

// Icons
$icon-size-small: 20px;
$icon-size-normal: 24px;
$icon-size-bigger: 30px;
