@import '../../variables';

.MapContainer {
    flex: 1;
    margin-top: 100px;
    width: 100%;
}

.Link:hover {
    text-decoration: underline;
}

.Link:visited {
    color: $color-black;
}
