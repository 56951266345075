@import '../../variables.scss';

.SoftwareUpdateContainer {
    width: 820px;
    text-align: left;
    font-size: $font-size-small;
}

.BrowserContainer,
.UrlContainer,
.UpdateTypeContainer {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.BrowserContainer {
    height: 220px;
}

.BrowserLabel,
.UrlLabel,
.UpdateTypeLabel {
    flex-basis: 110px;
}

.List {
    padding-left: 32px;
    list-style-type: none;
}

.Browser {
    flex: 1;
    border: 1px solid $color-shadow;
    overflow: auto;

    & > .List {
        padding: 0 1em;
    }
}

.UrlText {
    flex: 1;
    padding: 0 $padding;
    background: none;
    border: 1px solid $color-shadow;
}

.CopyLink {
    position: relative;
    margin-left: 12px;
    cursor: pointer;

    .CopyLinkImage {
        width: 24px;
        height: 24px;
    }
}

.SelectUpdateType {
    flex: 1;
    padding: 4px 12px;
    color: black;
    border: 1px solid $color-shadow;
}

.Message {
    position: relative;
    margin: 12px 0;
    text-align: center;
    height: 21px;
}

.ListItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &.Selected {
        font-weight: bold;
    }

    &:not(.Open) + .List {
        display: none;
    }
}

.ListItemImage {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}
