@import '../../variables.scss';
@import '../../mixins.scss';

$actionsHeight: 50px;
$titleHeight: 30px;
$tableSpacing: 1px;

.TableWrapper {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0;
}

.Caption {
    position: sticky;
    top: 0;
    z-index: $zIndex-tooltip;
    padding: 0 $space-normal;
    background-color: $color-light-gray;
    text-align: left;
    color: $color-text;

    .Title {
        height: $titleHeight;
        line-height: $titleHeight;
        font-weight: bold;

        @include ellipsisContent;
    }

    .Actions {
        height: $actionsHeight;
        display: flex;
        align-items: center;
        font-size: 0.9em;
        color: $color-text-dark;

        & > :first-child {
            margin-right: $space-normal;
        }

        .ActionItem {
            padding: $space-minimal;
            margin: 0 $space-minimal;
            border: solid 1px $color-text-pale;
            border-radius: 3px;

            & > :first-child {
                margin-right: $space-minimal;
            }
        }

        .Active {
            background-color: lighten($color-green, 15%);
            cursor: pointer;

            & > :first-child {
                font-weight: bold;
            }

            &:hover {
                background-color: lighten($color-green, 30%);
            }
        }

        .Disabled {
            background-color: $color-text-pale;
            color: $color-white;
            pointer-events: unset;
            cursor: not-allowed;
        }
    }
}

.Table {
    width: 100%;
    color: $color-text;
    border-spacing: 0 $tableSpacing;
    position: relative;

    th {
        position: sticky;
        top: 0;
        background-color: $color-text-pale;
        z-index: $zIndex-tooltip;
    }

    .LargeTopPosition th {
        top: calc(#{$actionsHeight} + #{$titleHeight}) !important;
    }

    .MediumTopPosition th {
        top: $actionsHeight !important;
    }

    .SmallTopPosition th {
        top: $titleHeight !important;
    }

    .Header {
        height: $list-header-height;
        color: $color-text-dark;
        font-size: 0.9em;
        text-align: left;

        .Checkbox {
            padding-left: $space-minimal;
            width: 40px;
        }

        .Column {
            position: relative;
            display: flex;
            align-items: center;
            min-width: 100px;
            padding: 0 $space-minimal;
            white-space: nowrap;
            font-weight: bold;
        }

        .Action {
            cursor: pointer;
        }
    }

    .Data {
        .Row {
            display: table-row !important;
            background-color: $color-white;
            text-align: left;
            height: $list-header-height;
            line-height: $list-header-height;
            box-shadow: 0 1px 2px $color-text-pale;

            & > td {
                padding-left: $space-minimal;
                word-break: break-all;
                max-width: 1px;
            }

            .Expanded,
            .Collapsed {
                vertical-align: middle;
                color: $color-blue;
                margin-left: 0;
                margin-right: $space-minimal;
            }

            .Collapsed {
                transform: rotate(-90deg);
            }

            &:hover {
                cursor: pointer;
                background-color: $color-magenta;
                color: $color-white;

                .Expanded,
                .Collapsed {
                    color: $color-white;
                }
            }
        }

        .RowExtra {
            background-color: $color-light-gray;
            text-align: center;

            .ExtraInfo {
                flex: 1;
                text-align: left;
            }
        }
    }

    .Empty {
        text-align: center;
    }

    .Footer {
        padding: $space-minimal;
        border: solid 1px $color-shadow;
        background-color: $color-text-pale;
    }
}

.Icon {
    width: 20px;
    height: 20px;
    color: $color-gray;
    cursor: pointer;
    margin-left: $space-minimal;
    vertical-align: middle;
}

.IconSmall {
    width: 14px;
    height: 14px;
    color: $color-text;
    cursor: pointer;
    margin-left: $space-minimal;
}

.IconBig {
    width: 24px;
    height: 24px;
    color: $color-white;
    background-color: $color-green;
    cursor: pointer;
    flex-basis: 24px;
    flex-shrink: 0;
    flex-grow: 0;
}

.Ascending {
    transform: rotate(180deg);
}

.FilterContainer {
    .Filter {
        position: absolute;
        left: 0;
        margin-top: $space-minimal;
        padding: $space-minimal;
        display: flex;
        flex: 1;
        z-index: $zIndex-important;
        background-color: $color-white;
        border: solid 1px $color-text-pale;
        min-width: 200px;
        cursor: default;

        input[type='search'] {
            -webkit-appearance: searchfield;
            outline: none;
            flex: 1;
            height: 24px;
            box-sizing: border-box;
            min-width: 100px;
            border: solid 1px $color-text-light;
        }

        input[type='search']::-webkit-search-cancel-button {
            -webkit-appearance: searchfield-cancel-button;
        }
    }
}

.ConfirmSection {
    padding: 8px $padding;
    background-color: $color-gray;
    color: $color-text-white;

    & > * {
        display: inline;
    }

    label {
        color: $color-text-white;
    }

    button:nth-child(n) {
        margin-left: $margin;
    }
}

.FullFilter {
    color: $color-blue-dark;
}
