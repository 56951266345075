@import '../../../variables.scss';

fieldset.Criteria {
    position: relative;
    margin: 0;
    padding: 16px 16px 16px 16px;
    border: 0;
    color: $color-text-light;
    font-size: $font-size-small;

    &.Hardware::before,
    &.Module::before,
    &.Software::before,
    &.Metadata::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        content: '';
    }

    &.Hardware::before {
        background-color: $color-magenta;
    }

    &.Software::before {
        background-color: $color-text-orange;
    }

    &.Module::before {
        background-color: $color-orange;
    }

    &.Metadata::before {
        background-color: $color-blue;
    }

    &:nth-child(2n) {
        background-color: $color-gray;
    }

    .Close {
        position: absolute;
        top: 8px;
        right: 0;
        z-index: $zIndex-base;
        background-color: transparent;
        color: $color-text-white;
    }

    &::after {
        position: absolute;
        bottom: -16px;
        left: 50%;
        z-index: $zIndex-base;
        box-sizing: border-box;
        padding: 4px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: $color-yellow;
        color: $color-text-gray;
        content: '&';
        font-weight: $font-weight-bold;
        font-size: $font-size-big;
        transform: translateX(-50%);
        animation: andShowUp 0.375s ease-in-out 1;
    }

    &:only-child {
        .Close {
            display: none;
            pointer-events: none;
        }

        &::after {
            display: none;
        }
    }

    &:last-child {
        &::after {
            display: none;
        }
    }

    ol {
        position: relative;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
        padding: 0;
        text-align: left;

        li {
            box-sizing: border-box;
            margin-bottom: 1em;
            padding: 0 16px;
            width: 100%;
            list-style: none;
            line-height: 24px;

            &:empty {
                display: none;
            }

            label {
                display: block;
                min-width: 73px;
                width: 100%;
            }

            input:not([type='radio']),
            select {
                box-sizing: border-box;
                padding: 0 8px;
                width: 100%;
                height: 32px;
                background-color: $color-white;
                line-height: 24px;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    fieldset.Criteria {
        ol {
            grid-template-columns: 1fr;
        }
    }
}

@keyframes andShowUp {
    0% {
        width: 24px;
        height: 48px;
        bottom: 100%;
        opacity: 0;
    }

    75% {
        bottom: -16px;
        width: 40px;
        height: 24px;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    fieldset.Criteria {
        ol {
            display: flex;
            flex-wrap: wrap;

            li {
                width: 50%;

                select {
                    padding-right: 0;
                }
            }
        }
    }
}

@media all and (max-width: 1280px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    fieldset.Criteria {
        ol {
            li {
                width: 100%;
            }
        }
    }
}

.DayPickerInput-OverlayWrapper,
.DayPicker-OverlayWrapper {
    z-index: $zIndex-tooltip;
    position: fixed !important;
}
