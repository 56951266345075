@import '../../variables.scss';

.SimpleQuery {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    margin-top: 66px;
    width: 75%;
    height: calc(100% - #{$page-header-height});

    > section {
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        width: 100%;
    }

    > section.Content {
        flex: 1;
        margin: $space-small 0 $space-normal;
        overflow: auto;
    }

    .loadingOuter {
        align-self: center;
    }

    .SimpleQueryForm {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        > button {
            margin-left: 1rem;
        }
    }
}
