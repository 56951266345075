@import '../../variables.scss';
@import '../../mixins.scss';

$snackbar-width: 300px;
$snackbar-darken-factor: 20%;

.Container {
    position: absolute;
    width: $snackbar-width;
    min-height: 50px;
    max-height: 200px;
    display: flex;
    flex-direction: row;
    z-index: $zIndex-snackbar;
    border-radius: 5px;
    animation-duration: 300ms;

    .MessageContainer {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-height: inherit;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        overflow: auto;
        direction: rtl;

        .Message {
            max-width: 100%;
            max-height: 100%;
            direction: ltr;
            word-wrap: break-word;
        }
    }

    .Action {
        display: flex;
        align-items: center;
        width: 30px;
        font-size: 30px;
        cursor: pointer;
    }
}

.Top {
    top: 0;
    left: calc(50vw - #{$snackbar-width/2});
    animation-name: slideAnimationTop;
}

.Bottom {
    bottom: 0;
    left: calc(50vw - #{$snackbar-width/2});
    animation-name: slideAnimationBottom;
}

.Left {
    left: 0 !important;
    right: unset;
    animation-name: slideAnimationLeftRight !important;
}

.Right {
    right: 0 !important;
    left: unset;
    animation-name: slideAnimationRightLeft !important;
}

.Error {
    color: $color-white;
    background-color: $color-text-orange;

    @include shadow($color-shadow);
}

.Info {
    color: $color-black;
    background-color: $color-light-gray;

    @include shadow($color-shadow);
}

.Success {
    color: $color-white;
    background-color: $color-green-dark;

    @include shadow($color-shadow);
}

@keyframes slideAnimationLeftRight {
    0% {
        transform: translate(-100%, 0%);
    }

    50% {
        transform: translate(-50%, 0%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

@keyframes slideAnimationRightLeft {
    0% {
        transform: translate(100%, 0%);
    }

    50% {
        transform: translate(50%, 0%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

@keyframes slideAnimationTop {
    0% {
        transform: translate(0%, -100%);
    }

    50% {
        transform: translate(0%, -50%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

@keyframes slideAnimationBottom {
    0% {
        transform: translate(0%, 100%);
    }

    50% {
        transform: translate(0%, 50%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}
