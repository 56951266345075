@import '../../variables.scss';

.Profile {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ProfileIcon {
        width: $icon-size-bigger;
        height: $icon-size-bigger;
    }

    .ProfileMenu {
        display: none;
        position: absolute;
        right: $space-normal;
        min-width: 140px;
        z-index: $zIndex-tooltip;
    }
}

.ProfileContainer:hover .ProfileMenu {
    display: flex;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Profile {
        width: initial;
    }
}
