@import '../../variables.scss';

.FormField.browseInstrumentData {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
    height: 36px;
    border-radius: 5px;
    background-color: $color-yellow;
    color: $color-text-gray;
    text-align: center;
    font-weight: 700;
    line-height: 36px;

    &:hover {
        background-color: $color-yellow-dark;
        color: $color-text-gray;
    }

    input {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: $zIndex-base;
        cursor: pointer;
    }

    label {
        text-align: center;
        line-height: 36px;
    }
}

.FormField.platformType {
    text-align: left;
}
