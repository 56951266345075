@import '../../variables.scss';

.ResultContainer {
    font-size: 0.9em;

    .Status {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    .NEW {
        color: $color-black;
    }

    .CANCELLED {
        color: $color-blue;
    }

    [class*='SUCCESS'],
    [class*='COMPLETED'] {
        color: $color-green;
    }

    [class*='FAILED'],
    [class*='ERROR'] {
        color: $color-red;
    }

    .SideInformationDetails {
        padding: $space-normal;

        .history h4 {
            margin: 0;
            padding: 0;
        }

        .InfoIcon {
            width: $icon-size-small;
            height: $icon-size-small;
            vertical-align: middle;
            margin-right: $space-minimal;
        }
    }
}
