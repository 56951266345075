@import '../../variables.scss';
@import '../../mixins.scss';

$item-padding: 5px;

.Container {
    position: relative;
    height: 100%;
}

.InputContainer {
    display: flex;
    height: 100%;
    border: solid 1px $color-text-pale;
}

.Input {
    outline: 0;
    flex: 1;
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 $item-padding;
}

.Highlight {
    font-weight: bold;
}

.InputAction {
    width: 55px;
    height: 100%;
    background-color: $color-green;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 5px;
        color: $color-white;

        &:hover {
            color: $color-white;
        }

        & > svg {
            width: 100%;
            height: 100%;
        }
    }

    &.Disabled {
        @include disabled($color-white, $color-gray);

        button {
            color: $color-white;
        }
    }
}

.SuggestionContainer {
    position: absolute;
    z-index: $zIndex-important;
    width: 100%;
    min-height: 32px;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $color-white;
    border: solid 1px $color-text-pale;
    box-sizing: border-box;
    border-top-width: 0;
    font-size: $font-size-small;
}

.SuggestionItem {
    text-align: left;
    padding: $item-padding;
    cursor: pointer;
}
