@import '../variables.scss';
@import '../mixins.scss';

.PageLayout {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    box-sizing: border-box;

    .LeftSidebar {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-width: 300px;

        .LeftSidebarMargin {
            height: $page-header-height;
        }
    }

    .Expanded {
        width: 30%;
        background-color: $color-black;
    }

    .Collapsed {
        width: 30px;
        background-color: $color-black;
    }

    .RightSideContainer {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: $query-container-top;
        min-width: 70%;
        flex: 1;
        flex-direction: column;
        align-items: center;

        article {
            width: 90%;
        }

        .RightSideVerticalContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow: auto;

            .InstrumentsContainer {
                position: relative;
                flex: 1 1;
                text-align: left;
                min-width: 800px;
            }
        }
    }
}

.InformationBlock {
    margin: 0 $margin;
    padding: $space-small;
    background-color: $color-light-gray;
    font-size: $font-size-small;

    @include shadow($color-shadow);

    .Row {
        display: flex;
        flex-direction: row;
        width: 100%;
        text-align: left;
        line-height: 24px;
    }

    .Label {
        font-weight: bold;
        white-space: nowrap;
    }

    .Value {
        width: 100%;
        padding: 0 5px;
        display: flex;
        flex-direction: row;
        word-break: break-all;

        .Text {
            display: inline-block;
            flex: 1;
            color: $color-blue-dark;
            word-break: break-all;
        }

        .Icon {
            margin: 0 $margin;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }
}
