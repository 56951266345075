@import '../../variables.scss';

.loadingOuter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: $color-text-orange;
    content: '';
    animation: init 0.375s ease-in-out;
}

.loadingInner {
    position: absolute;
    left: 50%;
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background-color: $color-white;
    content: '';
    transition: all 0.375s ease-in-out;
    transform: translateX(-50%);
    animation: bumping 2s ease-in-out infinite;
    z-index: $zIndex-tooltip;
}

.loadingOrbiter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    content: '';
    transition: all 0.375s linear;
    animation: orbiting 2s linear infinite;

    &::after {
        position: absolute;
        top: -75%;
        left: 50%;
        width: 37.5%;
        height: 37.5%;
        border-radius: 50%;
        background-color: $color-text-orange;
        content: '';
        transition: all 0.375s linear;
        transform: translateX(-50%);
    }
}

.loadingTextOuter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loadingTextInner {
    color: $color-text;
    width: 4.5em;
    text-align: left;

    &::after {
        content: '';
        animation: filling 2s ease-in-out infinite;
    }
}

@keyframes filling {
    0% {
        content: '';
    }

    25% {
        content: '.';
    }

    50% {
        content: '..';
    }

    75% {
        content: '...';
    }
}

@keyframes bumping {
    0% {
        bottom: 5%;
    }

    50% {
        bottom: 56%;
    }

    100% {
        bottom: 5%;
    }
}

@keyframes orbiting {
    0% {
        transform: rotate(-180deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes init {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
