@import '../../variables.scss';
@import '../../mixins.scss';

.Criterias {
    overflow: auto;
    box-sizing: border-box;
    min-width: 215px;
    font-size: 0;

    button.AddCriteria {
        @include add-button;

        .AddIcon {
            @include add-icon;
        }
    }
}
