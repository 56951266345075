@import '../variables.scss';
@import '../mixins.scss';

$filteredInputHeight: 32px;
$filteredItemSpace: 15px;
$filteredItemSpaceBetween: 5px;

@mixin filter-container {
    display: flex;
    padding: $filteredItemSpace;
    flex-direction: row;
    color: $color-text-pale;
}

@mixin filter-label {
    margin-right: 10px;
    min-width: 80px;
    height: $filteredInputHeight;
    line-height: $filteredInputHeight;
    flex-shrink: 0;
    text-align: left;
    text-transform: uppercase;
}

.FilteredList {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: $color-text-white;

    .FilteredListHeader {
        display: flex;
        flex-direction: row;
    }

    .FilteredListActions {
        flex-shrink: 0;
        width: 40px;
        padding-top: $filteredItemSpace;
    }

    .FilteredListFilters {
        flex: 1;
        min-width: 200px;
    }

    .FilteredListFilter {
        @include filter-container;

        .FilteredListInputLabel {
            @include filter-label;
        }

        .FilteredListInput {
            flex: 1;
            height: $filteredInputHeight;
            max-width: 500px;
            outline: none;
            box-sizing: border-box;
            padding: $filteredItemSpaceBetween;
        }

        .FilteredListIcon {
            width: $filteredInputHeight;
            height: $filteredInputHeight;
            margin-left: 10px;
            flex-shrink: 0;
        }
    }

    .FilteredListContent {
        overflow-y: auto;

        .FilteredListItem {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: $color-gray;
            border: solid 2px $color-black;
            border-left: solid 3px $color-text-orange;
            border-radius: 5px;

            @include ellipsisContent;

            &:hover {
                background-color: $color-text-pale !important;
                color: $color-text-dark;
            }

            .FilteredListItemTitle {
                flex: 1;
                text-align: left;
                color: inherit;
                display: inline-block;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding: $filteredItemSpaceBetween $filteredItemSpace;
            }

            .FilteredListItemAction {
                width: $filteredInputHeight;
                height: $filteredInputHeight;
                color: inherit;
                margin-right: $margin;

                svg {
                    width: $filteredInputHeight;
                    height: $filteredInputHeight;
                }
            }
        }

        .FilteredListItemSpecial {
            border-left: solid 3px $color-yellow;
        }

        .FilteredListItemSelected {
            background-color: $color-text-pale !important;
            color: $color-text-dark;
        }

        .FilteredListItemInactive {
            border-left: 3px solid $color-text;
        }
    }
}

.AdditionalFilterContainer {
    @include filter-container;

    height: $filteredInputHeight + $filteredItemSpace;

    .AdditionalFilterLabel {
        @include filter-label;
    }

    .AdditionalFilterInput {
        flex: 1 auto;
        height: $filteredInputHeight;
        color: $color-gray;
        background: $color-white;
    }
}
