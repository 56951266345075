@import '../../variables.scss';

.Label {
    position: relative;
    display: block;
    color: $color-text;
    text-align: left;
    font-size: $font-size-small;
    line-height: 24px;
}

.Input {
    display: block;
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    height: 34px;
    border: 1px solid $color-text-pale;
    color: $color-text;
    line-height: 34px;
}

.Select {
    display: block;
    box-sizing: border-box;
    padding: 0 12px;
    width: 100%;
    height: 34px;
    border: 1px solid $color-text-pale;
    color: $color-text;
    line-height: 34px;
}

.Message {
    position: absolute;
    bottom: -16px;
    color: $color-text-orange;
    font-size: $font-size-tiny;
}

.Multiline {
    box-sizing: border-box;
    padding: 8px 16px;
    min-width: 100%;
    min-height: 144px;
    max-width: 100%;
    border: 1px solid $color-text-pale;
    color: $color-text;
    line-height: 1.5em;
    resize: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Input {
        padding: 0 16px;
    }
}
