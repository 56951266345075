@import '../../variables.scss';

.DayPicker {
    font-size: $font-size-small;
    position: fixed;
    z-index: $zIndex-tooltip;
    margin-top: 4px;
    border: solid 1px $color-text-pale;
    background-color: $color-white;

    & *:focus {
        outline: none;
    }
}

.DayPicker-Day {
    border-radius: 0 !important;
    padding: 0.2em !important;
}

.DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.DayPicker-Day--start:not(.DayPicker-Day--outside),
.DayPicker-Day--end:not(.DayPicker-Day--outside) {
    background-color: $color-green !important;
    color: $color-text !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: lighten($color-green, 45%) !important;
    color: $color-green;
}
