@import '../../variables.scss';

.Menu {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    white-space: nowrap;
    color: $color-text-dark;
    font-size: 1em;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: initial;
}
