@import '../../variables.scss';

.UploadStatus {
    width: 60%;

    .ErrorInfo {
        font-size: $font-size-tiny;
    }

    .ErrorPath {
        position: relative;
        top: -10px;
    }
}
