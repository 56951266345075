@import '../../variables.scss';

.FormField {
    position: relative;
    margin-bottom: 24px;

    .Error {
        border: solid 1px $color-text-orange;
    }

    p.error {
        position: absolute;
        left: 0;
        margin: 0;
        text-align: left;
        font-size: $font-size-tiny;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .FormField {
        input,
        select {
            padding-right: 0;
        }
    }
}
