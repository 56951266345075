@import '../../../variables.scss';

.ExportForm {
    width: 200px;
}

.OptionLabel {
    display: flex;
    padding: 8px;
    cursor: pointer;
}

.OptionLabel:nth-child(2n + 1) {
    background: $color-light-gray;
}

.OptionCheckbox {
    width: 40px;
}

.ExportButton {
    margin-top: 40px;
    width: 100%;
}
