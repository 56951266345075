@import '../../variables.scss';

* {
    box-sizing: border-box;
}

html,
body {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font-stack;
}

input,
select,
button,
legend,
optgroup,
label,
datalist,
keygen,
fieldset {
    outline: none;
    font-family: $font-stack;
}

input,
select {
    border: solid 1px $color-text-pale;
}

.App {
    overflow: hidden;
    min-width: $minimum-app-width;
    width: 100vw;
    height: 100vh;
    text-align: center;
}

.Main {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    form {
        height: auto;
    }
}
