@import '../../variables.scss';
@import '../../mixins.scss';

.CreateModkitForm {
    min-width: 350px;
}

.Info {
    width: 100%;
    color: $color-text;
    text-align: left;
    font-size: 14px;

    p {
        min-height: 1.2em;
        height: auto;
        line-height: 1.2em;
    }
}

.ButtonGroup button {
    margin-bottom: $font-size-default;
}

.MessageContainer {
    position: relative;
    display: flex;
    margin-bottom: 24px;
    min-height: 48px;
    justify-content: center;
    align-items: center;
}

.Message {
    margin: 0;
    word-break: break-all;

    &.Success {
        color: $color-green;
    }

    &.Error {
        font-size: $font-size-tiny;
        color: $color-text-orange;
    }
}

.CreateModKitButton {
    @include add-button;
}

.AddIcon {
    @include add-icon;
}

.PlatformActions {
    height: 32px;
    margin-right: 10px;

    &:hover {
        background-color: $color-gray;
    }
}
