@import '../../variables.scss';
@import '../../mixins.scss';

a {
    display: block;
    text-decoration: none;
}

a:focus {
    outline: none;
}

button {
    outline: none;
    border: none;
}

.Default {
    overflow: hidden;
    flex-shrink: 0;
    box-sizing: border-box;
    padding: 0 16px;
    height: 36px;
    color: $color-text-dark;
    font-size: $font-size-default;
    line-height: 36px;
    cursor: pointer;

    &:hover {
        color: $color-text-orange;
    }

    &.Selected {
        border-bottom: solid 2px $color-text-orange;
    }

    &.Disabled {
        cursor: not-allowed;

        @include disabled($color-text-dark, $color-white);
    }

    &.ReadOnly {
        cursor: pointer;
        pointer-events: none;
    }
}

.Primary {
    border-radius: $border-radius;
    background-color: $color-blue;
    color: $color-text-white;
    font-weight: 700;
    font-size: $font-size-small;

    &:hover {
        background-color: $color-blue-dark;
        color: $color-text-white;
    }

    &:visited {
        color: $color-text-white;
    }

    &.Disabled {
        cursor: not-allowed;

        @include disabled($color-text-white, $color-blue);
    }

    &.ReadOnly {
        cursor: pointer;
        pointer-events: none;
    }
}

.Secondary {
    border-radius: $border-radius;
    background-color: $color-yellow;
    color: $color-text-gray;
    font-weight: 700;
    font-size: $font-size-small;

    &:hover {
        background-color: $color-yellow-dark;
        color: $color-text-gray;
    }

    &:visited {
        color: $color-text-gray;
    }

    &.Disabled {
        cursor: not-allowed;

        @include disabled($color-text-gray, $color-yellow);
    }

    &.ReadOnly {
        cursor: pointer;
        pointer-events: none;
    }
}

.Transparent {
    border: 0;
    background-color: transparent;
    color: $color-text-pale;

    // &:focus-visible
    &:visited,
    &:focus {
        outline: none;
        border: 0;
    }
}

.Warning {
    border-radius: $border-radius;
    background-color: $color-text-orange;
    color: $color-text-white;
    font-weight: 700;
    font-size: $font-size-small;

    &:hover {
        background-color: $color-text-orange-dark;
        color: $color-text-white;
    }

    &:visited {
        color: $color-text-white;
    }

    &.Disabled {
        cursor: not-allowed;

        @include disabled($color-text-dark, $color-text-orange);
    }

    &.ReadOnly {
        cursor: pointer;
        pointer-events: none;
    }
}

.Neutral {
    border-radius: $border-radius;
    background-color: $color-light-gray-2;
    color: $color-text-dark;
    font-weight: 700;
    font-size: $font-size-small;

    &:hover {
        background-color: $color-text-pale;
        color: $color-text-dark;
    }
}

.FullWidth {
    width: 100%;
}

.Clear {
    padding: 0;
    height: auto;
    background-color: transparent;
}
