@import '../../variables.scss';

.Header {
    position: fixed;
    top: 0;
    z-index: $zIndex-header;
    display: grid;
    align-items: center;
    grid-template-columns: calc(30% - 16px) 1fr;
    grid-gap: $space-normal;
    box-sizing: border-box;
    padding: 0 $space-normal;
    min-width: $minimum-app-width;
    width: 100%;
    height: $page-header-height;
    background-color: $color-white-opaque;

    .Navigation {
        display: flex;
        justify-content: space-between;
    }
}

.Logo {
    box-sizing: border-box;
    margin-left: auto;
    height: 29px;
}

@media screen and (max-width: 1366px) {
    .Header {
        grid-template-columns: calc(30% - 16px) 1fr;

        .Navigation {
            a {
                flex-shrink: 1;
            }
        }
    }
}

.LogoWrapper {
    display: inherit;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Header {
        display: -ms-grid;
        -ms-grid-columns: calc(30% - 16px) 1fr;
        -ms-grid-rows: $page-header-height;
    }

    .LogoWrapper {
        -ms-grid-column: 1;
        -ms-grid-column-align: end;
        -ms-grid-row-align: center;
    }

    .Navigation {
        -ms-grid-column: 2;
        margin-left: 16px;
    }
}
