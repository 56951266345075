@import '../../variables.scss';
@import '../../mixins.scss';

.ActionMenuWrapper {
    position: relative;
    min-width: 30px;
    min-height: 30px;
}

.Header {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.HeaderIcon {
    width: 24px;
    height: 24px;
}

.List {
    position: absolute;
    right: 0;
    z-index: 1000;
    margin: 0;
    padding: 0;
    max-height: 240px;
    overflow-y: auto;
    list-style-type: none;
    user-select: none;

    @include selection-container;
}

.ListItem {
    cursor: pointer;
    padding: 8px 12px;
    white-space: nowrap;

    &:hover {
        @include selected-item;
    }

    .ListItemIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 15px;
        vertical-align: sub;
    }

    .ListItemTitle {
        display: inline-block;
        font-size: $font-size-small;
        font-weight: normal;
    }
}
