@import '../../variables.scss';

.ProfileMenu {
    flex-direction: column;
    align-items: stretch;
    text-align: start;
    flex: 1;
    max-width: 300px;
    padding: $space-minimal;
    background-color: $color-black;
    border-radius: $border-radius;
    box-shadow: 4px 0 4px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.25);

    .UserName {
        color: $color-text-white;
    }

    .List {
        margin: $space-minimal 0 $space-normal 0;
    }

    .ListItem {
        list-style: none;
        font-size: $font-size-tiny;
        color: $color-white;
    }
}
