@import '../../variables.scss';

.RadioButtons {
    display: flex;
    justify-content: space-around;
}

.Radio {
    position: relative;

    fieldset {
        padding-bottom: 14px;
        border: 1px dashed $color-gray;
        border-radius: 5px;

        .RadioButtons {
            display: grid;
            align-content: center;
            align-items: center;
            justify-content: center;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
            justify-items: center;

            span {
                label {
                    display: inline;
                    margin-right: 16px;
                    line-height: 24px;
                }

                input {
                    position: relative;
                    margin-right: 0;
                    margin-left: 0;
                    width: 16px;
                    height: 16px;
                    border: none;
                    vertical-align: text-bottom;

                    &:focus,
                    &:visited {
                        outline: none;
                    }
                }
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Radio {
        fieldset {
            .RadioButtons {
                display: flex;
                justify-content: space-around;
            }
        }
    }
}
