@import '../../../variables.scss';
@import '../../../mixins.scss';

.VersionSelectorWrapper {
    height: $page-header-height;
    position: relative;
    display: flex;
    justify-content: stretch;
    cursor: pointer;

    .VersionSelector {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 280px;

        @include action-background;

        .Label {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: flex-start;
            text-align: left;
            width: 100%;
        }

        .Action {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 30px;
            padding: 0 $space-minimal;
        }

        .DropdownArrow {
            transition: transform 0.33s;

            &Open {
                transform: rotate(180deg);
            }
        }
    }

    .ItemInfo {
        @include info-styling;
    }

    .ItemsContainer {
        z-index: $zIndex-important;
        position: absolute;
        top: $page-header-height;
        left: 0;
        width: 100%;
        min-height: 60px;
        max-height: 140px;
        overflow-y: auto;
        box-sizing: border-box;

        @include selection-container;

        .ItemsList {
            display: table;
            border-collapse: collapse;
            text-align: left;
            width: 100%;

            td {
                @include info-styling;
            }
        }
    }

    .VersionInfo {
        min-width: 60px;
        width: 60px;
    }

    .Selected,
    .Item:hover {
        @include selected-item;
    }
}
