@import '../../variables.scss';

.ListElement {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;
    border-bottom: 1px solid $color-text-pale;
    color: $color-text;
    font-size: $font-size-small;

    span {
        display: inline-block;
        flex-shrink: 1;
        width: 100%;
    }

    &:hover {
        background-color: $color-magenta;
        color: $color-text-white;
    }

    &.ActiveModule {
        background-color: $color-orange;
        color: $color-text-white;
    }
}
