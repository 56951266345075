@import '../../../mixins.scss';

.ModulesList {
    list-style-type: none;

    .ListElement > span {
        max-width: 500px;

        @include ellipsisContent;
    }
}
