@import '../../variables.scss';

select {
    min-width: 80px;
    border: solid 1px $color-text-pale;
    background: transparent;
    color: $color-text;

    optgroup option {
        padding-left: 4px;
    }
}

.Disabled {
    background-color: $color-gray;
    color: $color-text-pale;
    cursor: not-allowed;
    pointer-events: none;
}
