@import './variables.scss';

@mixin selected-item {
    color: $color-text-dark;
    background-color: lighten($color-magenta, 35%);
}

@mixin action-background {
    background-color: darken($color-magenta, 16%);

    &:hover {
        background-color: darken($color-magenta, 8%);
    }
}

@mixin selection-container {
    color: $color-text;
    background-color: $color-white;
    box-shadow: -1px 1px 1px $color-shadow, 1px 0 0 $color-shadow;
}

@mixin info-styling {
    white-space: nowrap;
    padding: $space-minimal 0 $space-minimal $space-small;
}

@mixin add-button {
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    height: 72px;
    color: $color-white;
    background-color: $color-green;

    &:hover {
        color: $color-white;
    }
}

@mixin add-icon {
    height: 40px;
    color: currentColor;
    fill: currentColor;
}

@mixin ellipsisContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin disabled($color, $bg-color) {
    color: desaturate($color, 100%);
    background-color: rgba(desaturate($bg-color, 100%), 0.38);
}

@mixin tabEffects($color) {
    box-shadow: 0 -1px 0 0 $color inset;

    &.Active {
        box-shadow: 0 -4px 0 0 $color inset;
    }
}

@mixin shadow($color) {
    box-shadow: 1px 1px 5px 0 $color;
}

.container {
    max-width: 1500px;
}

.Menu {
    a:hover {
        text-decoration: none;
    }
}

@media screen and (max-width: 1366px) {
    .button_Default__34QgS {
        padding: 0 7px;
    }
}

.fix-nowrap {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-center {
    text-align: center;
    margin-top: 20px;
}
