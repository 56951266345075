@import '../../variables.scss';
@import '../../mixins.scss';

.Details {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: stretch;
    box-sizing: border-box;
    width: 100%;
    height: 90%;
    border-radius: 5px;
    background-color: $color-light-gray;
    box-shadow: 0 5px 15px 0 $color-text-pale;

    .headerInst,
    .headerMod {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-wrap: nowrap;
        justify-content: space-between;
        box-sizing: border-box;
        padding: $padding 0 $padding $padding;
        width: 100%;
        height: $page-header-height;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        color: $color-text-white;
        text-align: left;
        font-weight: $font-weight-bold;
        font-size: $font-size-big;

        .MaintenanceStatus {
            margin-right: $margin;
            border: solid 2px $color-text-white;
            flex-shrink: 0;
        }

        .primaryData {
            flex: 1 auto;

            @include ellipsisContent;
        }

        .audit {
            width: 280px;
            text-align: right;
            font-weight: $font-weight-default;
            font-size: $font-size-small;
            z-index: $zIndex-header;
        }

        & button {
            padding: 0 $padding;
            height: $page-header-height;
            color: $color-text-white;
            font-size: 30px;
            width: 60px;
            border-left: solid 1px $color-magenta;

            @include action-background;
        }

        & .saveButton {
            height: 28px;
            cursor: pointer;
        }
    }

    .DetailsActionMenu {
        min-width: 60px;
        height: $page-header-height;
        border-left: solid 1px $color-magenta;

        @include action-background;
    }

    .headerInst {
        background-color: $color-magenta;
    }

    .headerMod {
        background-color: $color-orange;
    }

    h4 {
        margin: 0;
        padding: $padding 0;
    }

    .Modules {
        position: relative;
        flex-shrink: 0;
        box-sizing: border-box;
        padding: 0 $padding $padding;
        width: 50%;
        color: $color-text;
        text-align: left;
        overflow: auto;

        h4 {
            position: sticky;
            top: 0;
            left: 0;
            z-index: $zIndex-base;
            background-color: $color-light-gray;
        }

        ul {
            margin-top: 0;
            padding: 0;
        }

        li:hover {
            background-color: $color-orange;
        }
    }

    .ExtraInfo {
        background-color: aliceblue;
        font-size: $font-size-small;

        .Properties {
            padding: $space-minimal;

            & > * {
                word-break: break-all;
            }

            p {
                margin: 0;
                padding: 0;
            }
        }
    }

    .ModuleProps {
        position: relative;
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        color: $color-text;
        text-align: left;
    }

    .AllProperties {
        overflow: auto;
        width: 100%;
        height: 100%;
        padding: 0 $padding $padding $padding;
        color: $color-text;
        text-align: left;
        word-break: break-all;

        .Properties {
            position: relative;
            overflow: auto;
            flex-shrink: 0;
            box-sizing: border-box;
            padding: 0 $padding $padding 0;
            width: 100%;

            h4 {
                position: sticky;
                top: 0;
                left: 0;
                z-index: $zIndex-base;
                background-color: $color-light-gray;
            }

            p {
                margin: 0;
                padding: 0;
                font-size: $font-size-small;
                line-height: 1.5em;
            }
        }
    }

    .Instrument {
        height: calc(100% - #{$page-header-height});
    }

    .Tags {
        display: block;
        text-align: left;
        color: $color-text;
        padding: 0 $padding;
    }

    .TagsList {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    .TagsListItem {
        font-size: $font-size-small;
    }

    .ModKitSection {
        width: 100%;

        .Applied {
            font-weight: bold;
            color: $color-blue;
        }

        .Revoked {
            font-weight: bold;
        }

        .Notapplied {
            font-weight: bold;
            color: $color-red;
        }

        .Retired {
            font-weight: bold;
        }
    }

    .NotificationsSection {
        width: 100%;

        .History {
            display: block;
        }

        .Status {
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &:hover & {
                color: $color-white;
            }
        }

        .NEW {
            color: $color-black;
            font-weight: bold;
        }

        .CANCELLED {
            color: $color-blue;
            font-weight: bold;
        }

        [class*='SUCCESS'],
        [class*='COMPLETED'] {
            color: $color-green;
            font-weight: bold;
        }

        [class*='FAILED'],
        [class*='ERROR'] {
            color: $color-red;
            font-weight: bold;
        }
    }

    .Icon {
        height: $icon-size-normal;
        width: $icon-size-normal;
        display: block;
    }

    .ClearLink {
        text-decoration: none;
        color: $color-text;
    }

    .MapSection {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ContentWithEllipsis {
        @include ellipsisContent;
    }
}

.MaintenanceIcon {
    width: 10px;
    height: 10px;
}
