@import '../../variables.scss';
@import '../../mixins.scss';

.ConfirmMessage {
    max-width: 600px;
    padding: 5px 0;
}

.ConfirmMessageHighlight {
    max-width: 550px;
    display: inline-block;
    font-weight: bold;
    vertical-align: bottom;

    @include ellipsisContent;
}

.ConfirmMessageHighlightSpace {
    max-width: 550px;
    font-weight: bold;

    &::before {
        content: ' ';
    }

    &::after {
        content: ' ';
    }
}

.ConfirmationMessageBreakWord {
    word-break: break-word;
}

.ConfirmButton {
    border-radius: 5px;
    margin: 5px 0;
}
