@import '../../../../variables.scss';

$row-height: 40px;

.CommentForm {
    width: 540px;
}

.FormField input {
    padding: 0 $padding;
    height: $row-height;
    color: $color-text;
}

.FormField {
    text-align: left;
    color: $color-text;

    label {
        display: block;
        line-height: $line-height;
        font-size: $font-size-small;
        text-align: left;
    }

    select {
        padding: 0 $padding;
        width: 100%;
        height: $row-height;
    }
}

.FormField textarea {
    height: 98px;
    min-height: auto;
}

#file-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}

label[for='file-input'] {
    display: flex;
    height: $row-height;

    .AttachmentName {
        flex: 1;
        margin-right: $space-small;
        padding: 0 $padding;
        border: 1px solid $color-text-pale;
    }

    .AddAttachment,
    .ClearAttachment {
        display: flex;
        justify-content: center;
        width: 80px;
        align-items: center;
        padding: 0 $padding;
        background: $color-text-pale;
        cursor: pointer;

        &:hover {
            background: $color-text-light;
        }
    }
}
