@import '../../../../variables';

$line-height: 36px;

.InfoContainer {
    display: flex;
    margin-top: $margin-small;
    text-align: left;
}

.Label {
    margin-right: $margin-small;
    font-weight: bold;
}

.MapContainer {
    flex: 1 1;
    width: 100%;
    margin: $margin 0;
    border: 1px solid $color-text-light;
}

.AddressContainer {
    position: relative;
    flex: 1;
}

.AddressInput {
    padding: 0 $padding;
    width: 100%;
    height: $line-height;
    font-size: $font-size-default;
}

.List {
    position: absolute;
    z-index: $zIndex-modal-top;
    margin: 0;
    padding: 0;
    width: 100%;
    background: $color-white;
    border: 1px solid $color-text-pale;
    list-style: none;
}

.ListElement {
    display: flex;
    align-items: center;
    padding: 0 $padding;
    height: $line-height;
    cursor: pointer;
}

.ListElement:nth-child(odd) {
    background: $color-light-gray;
}

.ListElement:hover {
    background: lighten($color-magenta, 30%);
}

.Button {
    margin-left: $margin-small;
    width: 80px;
}
